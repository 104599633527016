.dialog-demo .p-button {
    margin: 0 0.5rem 0 0;
    min-width: 10rem;
}

.dialog-demo p {
    margin: 0;
    line-height: 1.5;
}

.dialog-demo .p-dialog .p-button {
    min-width: 6rem;
}

.ocultar {
    display: none;
}

.footer-dialog {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: red;
    color: white;
    text-align: center;
}

.typePerson .p-component [data-icon]{
    margin: auto;
}