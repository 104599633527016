.enlace-grad-process a {
    margin-left: 15px;
}
.enlace-grad-process a  .p-menuitem-text{
    color: #428bca!important;
}
.enlace-grad-process a .p-menuitem-text:hover {
    color: #db4d59!important;
}

.title-enlace-grad-process{
    color:#0b5086!important;
}