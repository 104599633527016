.pdf-canvas canvas {
    width: 100%;
}

.pg-viewer-wrapper {
    overflow-y: hidden;
}

#react-doc-viewer #header-bar {
    display: none;
}
